import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import Modal from 'react-modal';
import logo from './signature_Logo.png';
import snake from './snake.png';
import line from './line.png';
import './App.css';

const customStyles = {
    modal: {
        margin: '0px',
        padding:'0px',
        border:'0px solid rgb(250, 249, 246)'
    },
    overlay: {
        padding:'0px',
        margin:'0px',
        backgroundColor: 'rgb(250, 249, 246, 0.8)'
    },
    content: {
        padding:'0px',
        margin:'0px',
        border:'0px solid rgb(250, 249, 246)'
    },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 50px;
  max-width: 75%;
  @media (max-width: 738px) {
    max-width:calc(100% - 32px);
    width:calc(100% - 32px);
    margin: 16px;
    padding:0px;
  }
`

const Border = styled.div`
  z-index:2;
  border-radius: 50px;
  padding: 50px;
  background-color: #FAF9F6;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  @media (max-width: 738px) {
    padding: 32px;
    padding-top: 45px;
  }
`

const MainLogo = styled.p`
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 0.8;
  font-family: 'Maru';
  text-transform: uppercase;
  font-size: 130px;
  text-wrap: wrap;
  margin: 0px;
  margin-bottom: 0px;
  padding-bottom:0px;
  text-align: left;
  letter-spacing: 0px;
  @media (max-width: 738px) {
    font-size: 90px;
  }
  @media (max-width: 420px) {
    font-size: 70px;
  }
  @media (max-width: 300px) {
    font-size: 45px;
  }
`

const BringClose = styled.span`
  letter-spacing: -10px;
  margin-right: 10px;
`

const Signature = styled.p`
  font-family: 'Maru';
  text-transform: uppercase;
  font-size: 26px;
  text-wrap: wrap;
  margin:0px;
  margin-bottom:6px;
`

const Date = styled.span`
  font-family: 'Marimaru';
  font-size: 26px;
  text-wrap: wrap;
  margin:0px;
`
const SignatureLogo = styled.img`
  width: 60px;
`

const Button = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  color: black;
  font-size: 20px;
  right: 300px;
  background-color: rgba(53,255,229, 0.9);
  border: 0px solid white;
  position: fixed;
`

const Line = styled.img`
  width:500px;
  margin-top:-16px;
  @media (max-width: 738px) {
    width:350px;
    margin-top:0px;
  }
  @media (max-width: 520px) {
    width:320px;
  }
  @media (max-width: 420px) {
    width:280px;
  }
  @media (max-width: 300px) {
    font-size: 45px;
  }
`

const Snake = styled.img`
  bottom:500px;
  left:0px;
  width:130%;
  position: fixed;
  opacity: 1;
  z-index:0;
`

const Snakes = styled.img`
  bottom:0px;
  left:0px;
  width:180%;
  position: fixed;
  opacity: 1;
  z-index:0;
  transform: rotate(80deg);
`

const Text = styled.p`
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 0.9;
  margin: 0px;
  margin-top: 16px;
  text-align: left;
  text-justify: inter-word;
  font-size: 32px;
  @media (max-width: 738px) {
    font-size: 28px;
  }
  @media (max-width: 420px) {
    font-size: 24px;
  }
  @media (max-width: 300px) {
    font-size: 22px;
  }
`

const Link = styled.a`
  cursor: pointer;
  //color: #007bff; /* Nice blue color */
  color: black;
  background-color: rgba(53,255,229, 0.2);
  //text-shadow: 3px 3px 3px #35ffe5, 5px 5px 5px #35ffe5, 0px 0px 20px #35ffe5;
  text-decoration: none; /* Remove default underline */
  transition: color 0.3s; /* Smooth color transition */
  &:hover {
    //text-shadow: 0px 0px 3px #f9e2c4, 0px 0px 3px #f9e2c4, 0px 0px 20px #ffa9ba;
    background-color: rgba(53,255,229, 0.3);
  }
`

const CloseLink = styled.a`
  position: fixed;
  top: 16px;
  right: 16px;
  //color: #007bff; /* Nice blue color */
  color: black;
  background-color: rgba(53,255,229, 0.4);
  //text-shadow: 3px 3px 3px #35ffe5, 5px 5px 5px #35ffe5, 0px 0px 20px #35ffe5;
  text-decoration: none; /* Remove default underline */
  transition: color 0.3s; /* Smooth color transition */
  &:hover {
    //text-shadow: 0px 0px 3px #f9e2c4, 0px 0px 3px #f9e2c4, 0px 0px 20px #ffa9ba;
    background-color: rgba(53,255,229, 0.3);
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 100px;
`

const WithMarginTop = styled.div`
  margin-top: 80px;
  //background-color: rgba(0,0,0,0.8);
  padding:6px;
  margin-bottom: 50px;
  width:300px;
`

function App() {
    const [db, setData] = useState({});
    const [pdfUrl, setPdfUrl] = useState('');
    const darkMode = false;
    const modalIsOpen = pdfUrl.length > 0;

    useEffect(() => {
        fetch("/db.json")
            .then(function (res) {
                return res.json();
            })
            .then(function (data) {
                setData(data);
            })
            .catch(function (err) {
                console.log(err, " error");
            });

    }, []);

    const news = db?.news || [];
    const docs = db?.documentation || [];
    const INSTA = 'https://www.instagram.com/thesetwentyoneletters/';
    const DOCUMENTATION = 'https://drive.google.com/drive/folders/1g0xPX9GTaDVgokS8COyk5ap_2Elmpuul?usp=sharing';
    const PUBLICATIONS ='https://drive.google.com/drive/folders/1DxsDR9aM3p9SMjEa8YdLZIrbkVgyjvbc?usp=sharing';
    const CV = 'https://mariannamaruyama.com/CV_2024_Marianna_Maruyama_3pages.pdf';
    return (
<>
          <Container>
              <Snake src={snake} alt="hand_drawn_snake" />
              <Snakes src={snake} alt="hand_drawn_snake_rotated" />
              <Border>
              {/*<PdfViewerModal pdfUrl={'https://mariannamaruyama.com/CV_2024_Marianna_Maruyama_3pages.pdf'}/>*/}
              <MainLogo>Maria<BringClose>nn</BringClose>a</MainLogo><MainLogo>Maru<BringClose>ya</BringClose>ma</MainLogo>
              <Line src={line} alt="hand_drawn_line" />
              <Text>Hi. I have been told that it’s hard to understand what I do from my website, and I agree.</Text>
              <Text>A website is a poor conductor of performance and sculpture, and doesn’t usually convey the experience of live readings, sound works, or conceptual artworks. Especially when those works reveal and cultivate interconnection, interdependency, and entanglement through broad topics such as translation, heritage, ecology, and love. Also, what I do is rarely a solo project, so it’s misleading when everything falls under one name, when in truth, I collaborate with historians, curators, restorers, composers, data scientists, entomologists, botanists, and others.</Text>
              <Text>So, instead of viewing this website as a comprehensive archive of my artistic practice, consider it more like a business card — an invitation to start something.</Text><br/><br/>
              <Text>You can find me at marianna.maruyama [at] gmail.com or <Link href={INSTA} target="_blank">@thesetwentyoneletters</Link> on instagram.</Text>
              <Text>You can download my portfolio <Link href={DOCUMENTATION} target="_blank">here</Link>.</Text>
              <Text>You can read some of the writing I have published <Link href={PUBLICATIONS} target="_blank">here</Link>.</Text>
              <Text>You can find my cv and bio <Link href={CV} target="_blank" >here</Link>.</Text>
              <Text>If you are in The Hague, please get in touch about visiting my studio.</Text><br/><br/>
              <Text>My practice has been generously supported by The Mondriaan Fond, Stroom Den Haag, Gemeente Den Haag, Stimuleringsfonds, FPK, KNIR/KNAW, and private collectors.</Text>
              <Text>But one way of personally supporting my work is to email me and let me know you’d like to subscribe to my newsletter.</Text>
              <Text>Another way is to invite me as a guest tutor or lecturer at your institution. I have tutored and lectured at master’s programs at the Dutch Art Institute and HEAD Genève, and at bachelor’s programs of the Piet Zwart Institute, Gerrit Rietveld Academie, Merz Akademie Stuttgart, and Oberlin College.</Text>
              {/*<Text>One more, much appreciated, way to support me is to become an owner of a limited edition work from my Merch page here.</Text>*/}
              <Text>A website is a small window at best. Thank you for opening it.</Text>
              <Footer><SignatureLogo src={logo} alt="Marianna Maruyama" /><Signature>Marianna Maruyama,<Date> 2024</Date></Signature></Footer>
              </Border>
          </Container>
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={()=> {
            setPdfUrl('');
        }}
        contentLabel="PDF Viewer Modal"
        style={customStyles}

    >
        <CloseLink onClick={()=> {
            setPdfUrl('');
        }}>Close</CloseLink>
        <iframe
            frameborder="0"
            title="PDF Viewer"
            src={pdfUrl}
            width="100%"
            height="100%"
        />
    </Modal>

</>
  );
}

export default App;
